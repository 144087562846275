<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>동영상</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">SUPPORT</a></li>
                        <li><a href="#">자료실</a></li>
                        <li><span>동영상</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section datamov">
            <div class="innWrap">
                <div class="datamovWrap">
                    <ul>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/iso3vzaE3YU?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">GiGAeyes</p>
                                <p>편의점</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/89sTkToFiN8?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">기업IoT전광판</p>
                                <p>동물병원</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="429" height="242" src="https://www.youtube.com/embed/YcYkINF1mEo?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">기업인터넷전화</p>
                                <p>네일샵</p>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/ZHoBX_rH_Pw?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">소호성공팩</p>
                                <p>카폐</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/EoQV8LB6HT4?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">기업메세징</p>
                                <p>부동산</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/GAbqAeE6l5U?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">등학교안심</p>
                                <p>유치원</p>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/t3aoTANxOhw?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">Kornet</p>
                                <p>PC방</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/KREQ37jhXvs?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">와이파이 홈</p>
                                <p>의류패션</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/N9D9IBeKjpo?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">링고비즈</p>
                                <p>플라워샵</p>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/kLUejpoXYf4?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">CCTV</p>
                                <p>약국</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/zXgCw2bcxBg?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">비즈메카 EZ</p>
                                <p>디자인편</p>
                            </div>
                        </li>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/GcJEYxOJVhM?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">비즈메카 콜센터 Lite</p>
                                <p>쇼핑몰편</p>
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li class="movWrap">
                            <div class="mov-thumb">
                                <iframe data-v-1e3590ae="" width="430" height="244" src="https://www.youtube.com/embed/cfz90wmdds0?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                            <div class="mov-txt">
                                <p class="tit">비즈메카 화상회의</p>
                                <p>여행사편</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {
    console.log(this.$route.query)
  },
  methods: {
  }
}
</script>